import React from "react";

import Header from "../partials/Header";
import Footer from "../partials/Footer";

import { run } from "tripetto-runner-autoscroll";
import Services from "tripetto-services";

const {
  definition,
  styles,
  l10n,
  locale,
  translations,
  // eslint-disable-next-line no-unused-vars
  snapshot,
  attachments,
  onSubmit,
  // eslint-disable-next-line no-unused-vars
  onPause,
} = Services.init({
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiUWw2NGdmeFBQczl0WEpnT01QSldCQjNDWDQrN2l2ejZpYkVpM09MUW42UT0iLCJkZWZpbml0aW9uIjoiUGhPVzQzemxISWUvbnZhTjR5c1pjaTJzR2tCSGZSM1RNSVlVL1N4SmN4WT0iLCJ0eXBlIjoiY29sbGVjdCJ9.3AvsOrL0wRSF6i98YzECsIeOoY1UViakW28wYkh8VjY",
});

export class Rules extends React.Component {
  componentDidMount() {
    run({
      element: document.getElementById("tripetto"),
      definition,
      styles,
      l10n,
      locale,
      translations,
      attachments,
      onSubmit,
    });
  }
  render() {
    return (
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          <section className="relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20 mx-4">
              <div className="max-w-6xl mx-auto">
                <h1
                  className="text-3xl md:text-4xl font-extrabold leading-tighter tracking-tighter mb-4"
                  data-aos="zoom-y-out"
                >
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                    Dragon Rush Registration
                  </span>
                </h1>
                {/* <p>Registration is not open at this time. Check back later!</p> */}
                <div id="tripetto"></div>
              </div>
            </div>
          </section>
          {/*  Page sections */}
        </main>
        <Footer />
      </div>
    );
  }
}

export default Rules;
