import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

function Footer() {
  return (
    <section className="relative">
      <div
        className="absolute inset-0 bg-white pointer-events-none"
        aria-hidden="true"
      ></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Have what it takes?</span>
            <span className="block text-blue-500">Register today.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://discord.gg/QRKENFW"
                className="btn text-blue-500 bg-white hover:bg-gray-100 shadow"
              >
                Discord
              </a>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <Link
                to="/register"
                className="btn text-white bg-blue-600 hover:bg-blue-700 shadow"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
