import React from "react";

import Header from "../partials/Header";
import Footer from "../partials/Footer";
import CTA from "../partials/CTA";

function Rules() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="relative">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20 mx-4">
            <article className="prose mx-auto">
              <h1>
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                  Dragon Rush
                </span>{" "}
                Rules
              </h1>
              {/* <p><strong>Dragon Rush 4 is over, but feel free to read the rules to get a sense of how the next event will be!</strong></p> */}
              <p>Dragon Rush is an event hosted by ScholarsMC, where teams of up to 4 battle against each other to be the first to kill the Ender Dragon.</p>
              <p>Dragon Rush 6 will take place on <strong>(unscheduled)</strong>. Log into the server at <strong>mc.scholarsmc.org</strong> five minutes before the event starts to join the group photo!</p>
              <p>Join us on Discord at <a href="https://discord.gg/QRKENFW">https://discord.gg/QRKENFW</a>.</p>
              <h2>
                Event Format
              </h2>
              <p>PvP is enabled and encouraged. Use it as a tactic to stop other teams from getting to the dragon first!</p>
              <p>You're able to teleport to teammates once every fifteen minutes. The warmup time is 15 seconds for teleports. Use this by typing <code>/tpa playername</code> and waiting for them to type <code>/tpaccept</code>.</p>
              <p>The team that has the player that gets the "Free the End" advancement is the winning team. This means you should kill anybody else in the End before attempting to take on the dragon to ensure your team will win!</p>
              <p>This event is limited to 16 teams or 64 participants, at the TO's discresion.</p>
              <p>Spawners in the nether will be unbreakable as there are a very small number of fortresses.</p>
              <p>World Borders: Overworld: 6000x6000, Nether: 4000x4000, End: 1250x1250.</p>
              <h2>Special Event Rules</h2>
              <p>Every player gets a potion effect from the list below! No two players on the same team may have the same potion effect. The person registering your team will select what potion effect you get.</p>
              <ul>
                <li>Fire Resistance</li>
                <li>Dolphin's Grace</li>
                <li>Haste III</li>
                <li>Speed II</li>
              </ul>
              <p>Night vision is available to all participants, toggleable with <code>/nv</code>.</p>
              <p>The world will be in 1.16.5, with 1.9-style PvP.</p>
              <p>Ender pearl drops from piglin trading is boosted to 5%, same as in 1.16.1.</p>
              <h2>Event Rules</h2>
              <p>Review these rules carefully! If any member of your team breaks these rules, your team will be disqualified.</p>
              <ol>
                <li><a href="https://scholarsmc.org/rules">ScholarsMC rules 1, 2, and 5 are in effect.</a> This means: don't cheat and be nice. If you are violate our rules during any of our events, your ban will be unappealable.</li>
                <li>Cross-teaming is prohibited. This means you should not work with or communicate with other teams in any way.</li>
                <li>Communication is limited to team members only. You should not give or receive information from anybody other than your teammates. While the event may be streamed, participants are prohibited from viewing the stream.</li>
                <li>At least half your team must consist of currently enrolled college/university students. We won't verify your status as a student, but we ask that you honor this requirement.</li>
                <li>Teams may consist of students from different colleges and universities.</li>
                <li>There are unlimited respawns. However, if the 15 minute teleport cooldown is still in effect, you won't be able to teleport to teammates.</li>
              </ol>
            </article>
          </div>
        </section>
        {/*  Page sections */}
        <CTA />
      </main>
      <Footer />
    </div>
  );
}

export default Rules;
