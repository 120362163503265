import React from 'react';

function FeaturesBlocks() {
  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2 z-10"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Previous Event Champions</h2>
            <p className="text-xl text-gray-600">Congratulations to our event champions!</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <img className="mb-2 -mt-1 p-1" src={require("../images/schools/columbia-wide.png")} alt="Columbia University Logo" />
              <p className="text-gray-600 text-center">Chrristianmono, Goldendrake, Horus_the_local, and Ramses2</p>
              <p className="text-xs text-gray-500 uppercase tracking-wider mt-3 -mb-4">Dragon Rush 1 · 8/7/2020</p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <img className="h-16 mb-2 -mt-1 p-1" src={require("../images/schools/berklee-wide.png")} alt="Berklee Logo" />
              <p className="text-gray-600 text-center">burncat_, koolkbraza (UCSB), windcat_, and worpp</p>
              <p className="text-xs text-gray-500 uppercase tracking-wider mt-3 -mb-4">Dragon Rush 2 · 9/4/2020</p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <img className="h-16 mb-2 -mt-1 p-1" src={require("../images/schools/harvard-wide.png")} alt="Harvard Logo" />
              {/* <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Harvard University</h4> */}
              <p className="text-gray-600 text-center">Myop1a (RIT), ManPris, Synchronous (Stanford)</p>
              <p className="text-xs text-gray-500 uppercase tracking-wider mt-3 -mb-4">Dragon Rush 3 · 10/2/2020</p>
            </div>

            {/* 4th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <img className="mb-2 -mt-1 p-1" src={require("../images/schools/columbia-wide.png")} alt="Columbia University Logo" />
              <p className="text-gray-600 text-center">Chrristianmono, Goldendrake, Horus_the_local, and Ramses2</p>
              <p className="text-xs text-gray-500 uppercase tracking-wider mt-3 -mb-4">Dragon Rush 4 · 12/29/2020</p>
            </div>

            {/* 5th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <img className="h-16 mb-2 -mt-1 p-1" src={require("../images/schools/berklee-wide.png")} alt="Berklee Logo" />
              <p className="text-gray-600 text-center">ZockerPlaysMC, burncat_, windcat_, oidoldol</p>
              <p className="text-xs text-gray-500 uppercase tracking-wider mt-3 -mb-4">Dragon Rush 5 · 5/21/2021</p>
            </div>

            {/* 6th item */}
            <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Dragon Rush 6</h4>
              <p className="text-gray-600 text-center">Coming Soon</p>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
